import { useEffect, useState } from 'react';
import { SectionTemplates, Story } from '../sections.type';

type Result = {
  templates: Story[];
  updateTemplates: (rows: Story[]) => void;
};

export const useGetSectionTemplates = (section: SectionTemplates): Result => {
  const [templates, setTemplates] = useState<Story[]>([]);

  useEffect(
    () =>
      setTemplates(
        section.payload.templates.map((template) => ({
          id: template.id,
          coverImage: template.imageUrl,
          name: template.name,
        })),
      ),
    [section.payload.templates],
  );

  const updateTemplates = (rows: Story[]) => setTemplates(rows);

  return { templates, updateTemplates };
};
