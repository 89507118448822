import { Button } from '@chakra-ui/react';
import { Icon, Link } from '@maestro/components';
import {
  ChapterFragment,
  ChapterStatus,
  ListChaptersQuery,
  useCreateChapterMutation,
} from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';
import { IconGems } from './IconGems';

type Props = {
  storyId: string;
  chapter: ChapterFragment;
  onPlayChapter: () => void;
};

export const ChapterRow: React.FC<Props> = ({
  storyId,
  chapter,
  onPlayChapter,
}) => {
  const chapterId = chapter.id;
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateChapterMutation({
    onSuccess() {
      queryClient.setQueryData<ListChaptersQuery>(
        ['listChapters', { storyId }],
        (old) => {
          if (!old) return old;

          return {
            ...old,
            chapters: old.chapters.map((chapter: ChapterFragment) =>
              chapter.id === chapterId
                ? { ...chapter, status: ChapterStatus.Creating }
                : chapter,
            ),
          };
        },
      );
    },
  });

  const onCreateChapter = () => {
    mutate({ input: { chapterId } });
  };

  return (
    <Container>
      <ChapterContainer>
        <ChapterOrder>{chapter.chapterNumber}</ChapterOrder>
        <ChapterDetails>
          <ChapterTitle>{chapter.title}</ChapterTitle>
          <ChapterDescription>{chapter.description}</ChapterDescription>
        </ChapterDetails>
        <ChapterButtons>
          {chapter.status === ChapterStatus.Ready && (
            <>
              <Link to={`/chapter-flow/${chapter.id}`}>
                <Button variant="default">Open Studio</Button>
              </Link>
              <Button
                variant="default"
                onClick={onPlayChapter}
                leftIcon={<Icon name="play" size={rawDimensions.size16} />}
              >
                Play
              </Button>
            </>
          )}
          {chapter.status === ChapterStatus.ReadyToCreate && (
            <Button
              onClick={onCreateChapter}
              isLoading={isLoading}
              variant="default"
              leftIcon={<IconGems />}
            >
              200 to create
            </Button>
          )}
          {(chapter.status === ChapterStatus.Playable ||
            chapter.status === ChapterStatus.Creating) && (
            <Button variant="default" isLoading>
              Create
            </Button>
          )}
          {chapter.status === ChapterStatus.Locked && (
            <Button
              variant="default"
              isDisabled
              leftIcon={<Icon name="locked" size={rawDimensions.size16} />}
            >
              Locked
            </Button>
          )}
        </ChapterButtons>
      </ChapterContainer>
    </Container>
  );
};

const Container = styled.div``;

const ChapterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
`;

const ChapterOrder = styled.div`
  ${textStyles.label.lb16sb}
  width: ${dimensions.size20};
  color: ${({ theme }) => theme.colors.text.header};
`;

const ChapterDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: center;
`;

const ChapterTitle = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ChapterDescription = styled.div`
  ${textStyles.body.b14m}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ChapterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
`;
