import type { StudioFlowState } from '@common/studio-types';
import {
  migrateRpgConfig,
  migrateStudioFlowState,
} from '@common/studio-types/migration';
import { cloneDeep } from 'lodash';
import { GeneratingAiContentError } from '../errors/errors';
import type { Game } from '../game';
import type { GameConfig } from '../game/gameConfig';
import { Message } from '../game/messages.types';
import type { PlayerData } from '../game/player';
import { getCurrentNodeAnalyticsData as nodeAnalyticsData } from './getCurrentNodeAnalyticsData';
import { getEpisodeData } from './getEpisodeData';
import { getGameNodes } from './getGameNodes';
import { handleCoinTossInteraction as coinToss } from './handleCoinTossInteraction';
import { handleContinueEpisode } from './handleContinueEpisode';
import { handleDiceRollInteraction as diceRoll } from './handleDiceRollInteraction';
import { handlePlayerInput as input } from './handlePlayerInput';
import { handleSingleSelectInteraction as select } from './handleSingleSelectInteraction';
import { handleTriggerItemActionInteraction as itemAction } from './handleTriggerItemActionInteraction';
import { init } from './init';
import { startGame } from './startGame';
import { StatefulGame, statefulGame } from './statefulGame';

/**
 * There are still a few things we need to do on this file:
 * 1. Validation of assumptions
 * 2. Unit Tests
 */

type CreateGameArgs = {
  config: GameConfig;
  studioFlowState: StudioFlowState;
  playerData: PlayerData;

  aiGeneration?: {
    refreshFlowState: () => Promise<StudioFlowState>;
    maxAttempts: number;
    waitTimeInMs: number;
  };
};

export const createGame = (args: CreateGameArgs): Game => {
  const { studioFlowState, playerData, config, aiGeneration } = args;

  if (Object.keys(config.rpgConfig).length > 0) {
    migrateStudioFlowState(studioFlowState, config.rpgConfig);
  }

  const gameNodes = getGameNodes(studioFlowState);

  if (Object.keys(config.rpgConfig).length > 0) {
    config.rpgConfig = migrateRpgConfig(config.rpgConfig);
  }

  const game = statefulGame(
    gameNodes,
    { playerData, state: null as never },
    config,
    args.playerData,
  );

  const episodeData = () => getEpisodeData(game, playerData, game.config);

  const handleInteraction = async (
    fn: () => Promise<Message[]>,
    attempt = 1,
  ): Promise<Message[]> => {
    const clonedState = cloneDeep(game.state());

    try {
      return await fn(); // return await to trigger catch
    } catch (error) {
      const isGenerating = error instanceof GeneratingAiContentError;

      if (isGenerating && aiGeneration && attempt < aiGeneration.maxAttempts) {
        // we need to roll back state if we are going to try again
        game.setGameState(clonedState);

        // let's wait a bit before trying again
        await new Promise((resolve) =>
          setTimeout(resolve, aiGeneration.waitTimeInMs),
        );
        const newState = await aiGeneration.refreshFlowState();
        gameNodes.refresh(newState);

        return handleInteraction(fn, attempt + 1); // let's try again
      }

      throw error;
    }
  };

  const wrap = async <T>(
    fn: (game: StatefulGame, args: T) => Promise<Message[]>,
    args: T,
  ): Promise<Message[]> => handleInteraction(() => fn(game, args));

  // prettier-ignore
  return {
    continueEpisode: () => handleInteraction(() => handleContinueEpisode(game)),
    init: (state) => init(game, state),
    coinToss: (interactionId, choice) => wrap(coinToss, { interactionId, choice }),
    rollDices: (interactionId) => wrap(diceRoll, { interactionId }),
    playerInput: (interactionId, playerInput) => wrap(input, { interactionId, playerInput }),
    singleSelect: (interactionId, optionId) => wrap(select, { interactionId, optionId }),
    triggerItemAction: async (itemId) => itemAction(game, { itemId, episodeData: await episodeData() }),
    episodeData,
    startGame: (nodeId) => startGame(game, nodeId),
    setState: (state) => game.setGameState(state),
    getState: () => game.state(),
    serialize: () => JSON.stringify(game.state()),
    getCurrentNodeAnalyticsData: () => nodeAnalyticsData(game),
    config,
  };
};
