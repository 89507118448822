import { StudioFlowState, StudioNodeData } from '@common/studio-types';
import { useGetChapterFlowQuery } from '@maestro/graphql';
import { Drawer } from '@maestro/studio/components/Drawer';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import { Edge, Node } from 'reactflow';
import styled from 'styled-components';
import { TableLoader } from '../../../components/TableLoader';
import { PlayChapterSimulator } from './PlayChapterSimulator';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  chapterId?: string;
};

export const PlayChapterDrawer: React.FC<Props> = (props) => {
  const [flowState, setFlowState] =
    useState<StudioFlowState<Node<StudioNodeData>, Edge>>();
  const { data, isLoading } = useGetChapterFlowQuery(
    { chapterId: props.chapterId! },
    { enabled: !!props.chapterId },
  );

  useEffect(() => {
    if (data?.chapterFlow.flow) {
      setFlowState(
        data.chapterFlow.flow as StudioFlowState<Node<StudioNodeData>, Edge>,
      );
    }
  }, [data]);

  return (
    <>
      {props.isOpen && <Backdrop onClick={props.onClose} />}
      <Drawer
        isOpen={props.isOpen}
        onCancel={props.onClose}
        title="Play chapter"
      >
        {isLoading && <TableLoader />}
        {flowState && props.isOpen && (
          <PlayChapterSimulator flowState={flowState} />
        )}
      </Drawer>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  z-index: 10;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.1);
`;
