import { CharacterFragment } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  characters: CharacterFragment[];
  selectedCharacterIds: string[];
  onSelectCharacter: (characterId: string) => void;
};

export const CharactersSelector: React.FC<Props> = ({
  characters,
  selectedCharacterIds,
  onSelectCharacter,
}) => {
  return (
    <Container>
      {characters.map((character) => (
        <CharacterContainer
          key={character.id}
          $isSelected={selectedCharacterIds.includes(character.id)}
          onClick={() => onSelectCharacter(character.id)}
        >
          <CharacterImage src={character.avatarUrl!} />
          {!!selectedCharacterIds.find((id) => id === character.id) && (
            <CharacterOrder>
              {selectedCharacterIds.findIndex((id) => id === character.id) + 1}
            </CharacterOrder>
          )}
        </CharacterContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${dimensions.size12};
  overflow-y: auto;
  max-height: ${dimensions.size360};
  margin-bottom: ${dimensions.size12};
`;

const CharacterImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: ${dimensions.size8};
  user-drag: none;
  -webkit-user-drag: none;
`;

const CharacterContainer = styled.div<{ $isSelected: boolean }>`
  width: calc(50% - ${dimensions.size12});
  border-radius: ${dimensions.size12};
  border: 3px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.background.accent : 'transparent'};
  position: relative;
`;

const CharacterOrder = styled.div`
  ${textStyles.title.t12b}
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.background.accent};
  width: ${dimensions.size24};
  height: ${dimensions.size24};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${dimensions.size999};
  position: absolute;
  bottom: ${dimensions.size8};
  right: ${dimensions.size8};
`;
