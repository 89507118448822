import {
  DiscoverySectionFragment,
  DiscoverySectionStoryTemplatesFragment,
  DiscoverySectionType,
} from '@maestro/graphql';

export type Story = {
  id: string;
  name: string;
  coverImage: string;
};

export type DiscoverySectionItem<TData = unknown> = {
  id: string;
  name: string;
  order: number;
  type: DiscoverySectionType;
  payload: TData;
};

export type SectionTemplates =
  DiscoverySectionItem<DiscoverySectionStoryTemplatesFragment>;

export const toItem = (
  message: DiscoverySectionFragment,
): DiscoverySectionItem<unknown> => {
  return message as unknown as DiscoverySectionItem<unknown>;
};

export const isTemplatesSection = (
  message: DiscoverySectionItem<unknown>,
): message is SectionTemplates => {
  return message.type === DiscoverySectionType.StoryTemplates;
};
