import { AuthGuard } from '@maestro/auth';
import { SentryRoutes } from '@maestro/monitoring/sentry';
import { Studio } from '@maestro/studio';
import { ErrorComponent } from '@refinedev/chakra-ui';
import { Authenticated } from '@refinedev/core';
import { CatchAllNavigate, NavigateToResource } from '@refinedev/react-router';
import { Outlet, Route } from 'react-router';
import { AdminGuard } from './auth/AdminGuard';
import { MainContainer } from './components/MainContainer';
import { Maintenance } from './components/Maintenance';
import { RedirectTo } from './RedirectTo';
import { LoginPass } from './resources/auth/LoginPass';
import { LoginScreen } from './resources/auth/LoginScreen';
import { SupabaseRedirect } from './resources/auth/SupabaseRedirect';
import { Username } from './resources/auth/Username';
import { DiscoverySections } from './resources/discoverySections/DiscoverySections';
import { MyStudio } from './resources/myStudio/MyStudio';
import { SeriesDetailsPage } from './resources/series/SeriesDetailsPage';
import { SeriesList } from './resources/series/SeriesList';
import { ChapterFlow } from './resources/story/myStories/ChapterFlow';
import { MyStories } from './resources/story/myStories/MyStories';
import { StoryDetails } from './resources/story/myStories/StoryDetails';
import { StoryFactoryCreate } from './resources/story/storyFactory/StoryFactoryCreate';
import { StoryFactoryDetails } from './resources/story/storyFactory/StoryFactoryDetails';
import { StoryFactoryEdit } from './resources/story/storyFactory/StoryFactoryEdit';
import { StoryFactoryList } from './resources/story/storyFactory/StoryFactoryList';
import { StoryFactoryBranchCreate } from './resources/story/storyFactoryBranch/StoryFactoryBranchCreate';
import { StoryFactoryBranchDetails } from './resources/story/storyFactoryBranch/StoryFactoryBranchDetails';
import { StoryFactoryBranchEdit } from './resources/story/storyFactoryBranch/StoryFactoryBranchEdit';
import { StoryFactoryBranchList } from './resources/story/storyFactoryBranch/StoryFactoryBranchList';
import { WorldCreate } from './resources/world/WorldCreate';
import { WorldDetails } from './resources/world/WorldDetails';
import { WorldEdit } from './resources/world/WorldEdit';
import { WorldList } from './resources/world/WorldList';

export const MaestroRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/login-4cf9cc4bc2eb" element={<LoginPass />} />
      <Route
        element={
          <Authenticated
            key="authenticated"
            fallback={<CatchAllNavigate to="/login" />}
          >
            <AuthGuard>
              <MainContainer />
            </AuthGuard>
          </Authenticated>
        }
      >
        <Route index element={<WorldList />} />
        <Route path="/my-studio" element={<MyStudio />} />
        <Route path="/my-stories" element={<MyStories />} />
        <Route path="/story-details/:id" element={<StoryDetails />} />
        <Route path="/chapter-flow/:id" element={<ChapterFlow />} />
        <Route path="/world">
          <Route index element={<RedirectTo path="/" />} />
          <Route path="create" element={<WorldCreate />} />
          <Route path=":id/edit" element={<WorldEdit />} />
          <Route path=":id" element={<WorldDetails />} />
        </Route>

        <Route
          path="/discovery-sections"
          element={
            <AdminGuard>
              <DiscoverySections />
            </AdminGuard>
          }
        />
        <Route path="/series">
          <Route index element={<RedirectTo path="/my-studio" />} />
          <Route
            path="all"
            element={
              <AdminGuard>
                <SeriesList />
              </AdminGuard>
            }
          />
          <Route path=":id" element={<SeriesDetailsPage />} />
        </Route>

        <Route path="/story-factory">
          <Route
            index
            element={
              <AdminGuard>
                <StoryFactoryList />
              </AdminGuard>
            }
          />
          <Route path="create" element={<StoryFactoryCreate />} />
          <Route path=":id" element={<StoryFactoryDetails />} />
          <Route path=":id/edit" element={<StoryFactoryEdit />} />
          <Route path=":id/new" element={<StoryFactoryBranchCreate />} />
        </Route>
        <Route path="/story">
          <Route
            index
            element={
              <AdminGuard>
                <StoryFactoryBranchList />
              </AdminGuard>
            }
          />
          <Route path=":id" element={<StoryFactoryBranchDetails />} />
          <Route path=":id/edit" element={<StoryFactoryBranchEdit />} />
        </Route>
        <Route path="/series/:seriesId/episode">
          <Route index element={<RedirectTo path="/series/:seriesId" />} />
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route path="/sb" element={<SupabaseRedirect />} />
      <Route
        element={
          <Authenticated key="alreadyAuthenticated" fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<LoginScreen />} />
      </Route>
      <Route
        path="/studio/:id"
        element={
          <AuthGuard>
            <Studio />
          </AuthGuard>
        }
      />
      <Route path="/username" element={<Username />} />
      <Route path="/maintenance" element={<Maintenance />} />
    </SentryRoutes>
  );
};
