import { StudioFlowState, StudioNodeData } from '@common/studio-types';
import { useGetChapterFlowQuery } from '@maestro/graphql';
import { StudioFlow } from '@maestro/studio/StudioFlow';
import { dimensions } from '@maestro/styles';
import { useParams } from 'react-router';
import { Edge, Node, ReactFlowProvider } from 'reactflow';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState';
import { TableLoader } from '../../../components/TableLoader';
import { ChaptersAutoLayout } from './ChaptersAutoLayout';
import { PlayChapterButton } from './PlayChapterButton';

const useGetChapterFlow = (
  chapterId: string,
):
  | { isLoading: true }
  | {
      isLoading: false;
      flow: StudioFlowState<Node<StudioNodeData>, Edge> | null;
    } => {
  const { data, isLoading } = useGetChapterFlowQuery({ chapterId });

  if (isLoading) return { isLoading: true };

  if (!data || !data.chapterFlow.flow) {
    return { isLoading: false, flow: null };
  }

  const flow = data.chapterFlow.flow as StudioFlowState<
    Node<StudioNodeData>,
    Edge
  >;

  return { isLoading: false, flow };
};

export const ChapterFlow = () => {
  const params = useParams();
  const chapterId = params.id!;
  const result = useGetChapterFlow(chapterId);

  const noop = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  if (result.isLoading) return <TableLoader />;

  if (!result.flow) return <EmptyState title="Chapter not found" />;

  return (
    <ReactFlowProvider>
      <FloatingPlayButton>
        <PlayChapterButton />
      </FloatingPlayButton>
      <ChaptersAutoLayout />
      <StudioFlow
        onChange={noop}
        hideSidebar
        initialNodes={result.flow.nodes}
        initialEdges={result.flow.edges}
      />
    </ReactFlowProvider>
  );
};

const FloatingPlayButton = styled.div`
  position: absolute;
  z-index: 10;
  top: ${dimensions.size16};
  right: ${dimensions.size16};
`;
