import { useAutoLayout } from '@maestro/studio/hooks/useAutoLayout';
import { useEffect } from 'react';
import { useReactFlow } from 'reactflow';

export const ChaptersAutoLayout = () => {
  const { setNodes, setEdges, getNodes, getEdges, fitView } = useReactFlow();
  const autoLayout = useAutoLayout();

  const organizeNodes = () => {
    const { nodes, edges } = autoLayout(getNodes(), getEdges());
    setNodes(nodes);
    setEdges(edges);
    setTimeout(() => fitView({ duration: 300 }), 100);
  };

  useEffect(() => {
    setTimeout(() => organizeNodes(), 100);
  }, []);

  return null;
};
