/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EpisodeSetupNodeData,
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
  StudioFlowState,
  StudioNodeData,
  StudioNodeType,
  ValueType,
} from '../..';
import { MigrateStudioFlow } from '../migration.types';
import { migrateProperty, remapValue } from '../v0tov2.utils';

type Node<TData extends StudioNodeData> = {
  data: TData;
};

export const v0tov2: MigrateStudioFlow = (
  state: StudioFlowState,
  config: RpgConfig,
): void => {
  if (state.version >= 2 || Object.keys(state).length === 0) {
    return;
  }

  const data = state.nodes;
  const setupNodes: Node<EpisodeSetupNodeData>[] = data
    .filter((node) => node.data.type === StudioNodeType.EpisodeSetup)
    .map((node) => {
      if (
        !state.version && // will ignore version 1 because it's already migrated
        node.data.type === StudioNodeType.EpisodeSetup &&
        !node.data.version
      ) {
        node.data.properties = node.data.properties.map(
          (property): RpgConfigProperty =>
            migrateProperty(property, PropertyScope.Episode),
        );
      }

      return node;
    }) as Node<EpisodeSetupNodeData>[];

  const episodeProperties: RpgConfigProperty[] = setupNodes.flatMap(
    (node) => node.data.properties,
  );
  const rpgConfig = {
    ...config,
    properties: [...config.properties, ...episodeProperties],
  };

  data.forEach((node) => {
    if ((state.version as number) === 1) {
      // for version 1 it'll remap dice roll only
      if (node.data.type === StudioNodeType.DiceRoll) {
        if (node.data.modifiers.length) {
          node.data.modifiers.forEach((modifier) => {
            remapValue(modifier.value, rpgConfig);
          });
        }
      }

      // only dice roll will be remapped in version 1, the other ones are migrated already
      return;
    }

    if (node.data.type === StudioNodeType.ConditionCheck) {
      remapValue(node.data.valueA, rpgConfig);
      remapValue(node.data.valueB, rpgConfig);
    } else if (node.data.type === StudioNodeType.ChangeValue) {
      remapValue(node.data.changedValue, rpgConfig);

      if (node.data.value) {
        remapValue(node.data.value, rpgConfig);
      }
    } else if (node.data.type === StudioNodeType.DiceRoll) {
      if (node.data.modifiers.length) {
        node.data.modifiers.forEach((modifier) => {
          remapValue(modifier.value, rpgConfig);
        });
      }
    } else if (node.data.type === StudioNodeType.GetValue) {
      remapValue(node.data.value, rpgConfig);
    } else if (node.data.type === StudioNodeType.CoinToss) {
      const oldNode = node.data as any;
      const attributeRef: string | undefined = oldNode.attributeRef;

      if (attributeRef) {
        oldNode.attributeRef = attributeRef;

        const property = rpgConfig.properties.find(
          (prop) =>
            prop.id === attributeRef && prop.scope === PropertyScope.Series,
        );

        if (property) {
          node.data.property = {
            dataType: property.config.dataType,
            ref: property.id,
            type: ValueType.Property,
          };
        }
      }
    }
  });
};
