export const IconGems = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8084_12077)">
        <path
          d="M5.77778 1.3335H3.11111L0 5.3335H4.44444L5.77778 1.3335Z"
          fill="white"
        />
        <path
          d="M15.9999 5.3335L12.8888 1.3335H10.2222L11.5555 5.3335H15.9999Z"
          fill="#AB59C9"
        />
        <path d="M11.5556 5.3335H16L8 14.6668L11.5556 5.3335Z" fill="#5D2B6F" />
        <path
          d="M4.44444 5.3335H0L8 14.6668L4.44444 5.3335ZM5.77778 1.3335L4.44444 5.3335H11.5556L10.2222 1.3335H5.77778Z"
          fill="#E6A1FF"
        />
        <path
          d="M7.99989 14.6668L4.44434 5.3335H11.5554L7.99989 14.6668Z"
          fill="#AB59C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_8084_12077">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
