import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import {
  StoryMode,
  useCreateStoryOutlineMutation,
  useGetCharactersQuery,
  useTemplateQuery,
} from '@maestro/graphql';
import { Drawer } from '@maestro/studio/components/Drawer';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TableLoader } from '../../../components/TableLoader';
import { CharactersSelector } from './CharactersSelector';
import { StoryCreationForm } from './StoryCreationForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  branchId: string;
};

export const CreateStoryDrawer: React.FC<Props> = (props) => {
  const navigate = useNavigation();
  const { isOpen, onClose, branchId } = props;
  const { data, isLoading } = useTemplateQuery({ id: branchId });
  const { data: charactersData } = useGetCharactersQuery();
  const [values, setValues] = useState<Record<string, string | number>>({});
  const { mutateAsync: createStoryOutline, isLoading: isGenerating } =
    useCreateStoryOutlineMutation();
  const [selectedCharacterIds, setSelectedCharacterIds] = useState<string[]>(
    [],
  );

  const closeClickHandler = () => {
    setValues({});
    setSelectedCharacterIds([]);
    onClose();
  };

  const generateStory = async () => {
    const result = await createStoryOutline({
      input: {
        characterIds: selectedCharacterIds,
        props: values,
        factoryBranchId: branchId,
        mode: StoryMode.Book,
      },
    });

    navigate.push(
      `/story-details/${result.createStoryOutline.factoryBranchId}`,
    );
  };

  const onSelectCharacter = (characterId: string) => {
    if (selectedCharacterIds.includes(characterId)) {
      setSelectedCharacterIds(
        selectedCharacterIds.filter((id) => id !== characterId),
      );
    } else {
      setSelectedCharacterIds([...selectedCharacterIds, characterId]);
    }
  };

  const onFieldsChange = (fieldId: string, value: string | number) => {
    setValues({ ...values, [fieldId]: value });
  };

  const templateFields = data?.template?.form?.fields ?? [];
  const isValid = useMemo(() => {
    const keys = Object.keys(values);
    const fieldDefKeys = templateFields.map(({ id }) => id);
    const allFieldsFilled = fieldDefKeys.every((key) => !!values[key]);

    return keys.every((key) => !!values[key]) && allFieldsFilled;
  }, [values, templateFields]);

  return (
    <Drawer isOpen={isOpen} onCancel={closeClickHandler} title="Create story">
      <Container>
        {isLoading && <TableLoader />}
        {!isLoading && (
          <>
            <DrawerBody>
              <ContentColumn $needsFields={templateFields.length > 0}>
                {!templateFields.length && (
                  <Description>Creating new story</Description>
                )}

                <CharactersSelector
                  characters={charactersData?.characters ?? []}
                  selectedCharacterIds={selectedCharacterIds}
                  onSelectCharacter={onSelectCharacter}
                />

                <StoryCreationForm
                  allowEmpty={false}
                  templateFields={templateFields}
                  values={values}
                  onChange={onFieldsChange}
                />
              </ContentColumn>
            </DrawerBody>
            <Footer>
              <Button
                flex={1}
                isLoading={isGenerating}
                isDisabled={!isValid}
                leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
                variant="primary"
                onClick={generateStory}
              >
                Play
              </Button>
            </Footer>
          </>
        )}
      </Container>
    </Drawer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DrawerBody = styled.div`
  display: flex;
  gap: ${dimensions.size24};
  align-items: start;
  padding: ${dimensions.size24};
  flex: 1;
  overflow-y: auto;
`;

const ContentColumn = styled.div<{ $needsFields: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $needsFields }) => ($needsFields ? 'normal' : 'center')};
  gap: ${dimensions.size12};
  flex: 1;
`;

const Description = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: ${dimensions.size12};
  padding: ${dimensions.size12};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default[100]};
`;
