import { Badge, useDisclosure } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import {
  HeaderContainer,
  HeaderImage,
  HeaderRowContainer,
  HeaderTitle,
  HeaderTitleContainer,
  HeaderVerticalWrapper,
  ImageWrapper,
  ScreenContainer,
  Section,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { ImageFallback } from '@maestro/components/ImageFallback';
import {
  useGetStoryFactoryBranchQuery,
  useListChaptersQuery,
} from '@maestro/graphql';
import { EditEpisodeModal } from '@maestro/studio/components/episode/EditEpisode/EditEpisodeModal';
import { EditSeriesModal } from '@maestro/studio/components/series/EditSeries/EditSeriesModal';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState';
import { TableLoader } from '../../../components/TableLoader';
import { ChapterRow } from './ChapterRow';
import { useWatchStoryEvents } from './hooks/useWatchStoryEvents';
import { PlayChapterDrawer } from './PlayChapterDrawer';

export const StoryDetails = () => {
  const params = useParams();
  const storyId = params.id!;
  const { data, isLoading } = useGetStoryFactoryBranchQuery(
    { id: storyId },
    { queryKey: ['listChapters', { id: storyId }] },
  );
  const { data: chapters, isLoading: isLoading2 } = useListChaptersQuery({
    storyId,
  });
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const [selectedChapterId, setSelectedChapterId] = useState<string>();

  const playChapter = (chapterId: string) => {
    setSelectedChapterId(chapterId);
    onDrawerOpen();
  };

  const story = data?.storyFactoryBranch;

  useWatchStoryEvents(storyId);

  if (isLoading || isLoading2) return <TableLoader />;

  if (!story) return <EmptyState title="Story not found" />;

  return (
    <>
      <ScreenContainer>
        <PlayChapterDrawer
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          chapterId={selectedChapterId}
        />

        <StyledHeaderContainer>
          <ImageHeaderContainer>
            {story.coverImage ? (
              <MobileSeriesHeaderImage src={story.coverImage} />
            ) : (
              <ImagePlaceholder />
            )}
            <StyledHeaderRowContainer>
              <StoryHeaderImage>
                <ImageWrapper borderRadius={rawDimensions.size8}>
                  {story.coverImage ? (
                    <HeaderImage
                      $width={160}
                      $height={240}
                      src={toOptimizedImageUri(story.coverImage, {
                        width: rawDimensions.size160,
                        height: rawDimensions.size240,
                        dpr: window.devicePixelRatio,
                        resize: 'cover',
                      })}
                    />
                  ) : (
                    <ImageFallback
                      width={160}
                      height={240}
                      iconSize={rawDimensions.size32}
                    />
                  )}
                </ImageWrapper>
              </StoryHeaderImage>
              <StyledHeaderTitleContainer>
                <HeaderVerticalWrapper>
                  {!!story.category && (
                    <Badge variant="statusActive">{story.category.name}</Badge>
                  )}
                  <HeaderTitle aria-label="Story title">
                    {story.title}
                  </HeaderTitle>
                  {story.description && (
                    <StoryDescription>{story.description}</StoryDescription>
                  )}
                </HeaderVerticalWrapper>
              </StyledHeaderTitleContainer>
            </StyledHeaderRowContainer>
          </ImageHeaderContainer>
        </StyledHeaderContainer>

        <SectionContainer>
          <Section>
            <SectionTitle>Chapters</SectionTitle>
            <SectionCard>
              <StoryListContainer>
                {chapters?.chapters.map((chapter, index) => (
                  <div key={chapter.id}>
                    <ChapterRow
                      storyId={storyId}
                      chapter={chapter}
                      onPlayChapter={() => playChapter(chapter.id)}
                    />
                    {index < chapters.chapters.length - 1 && (
                      <Separator key={`chapter-row-separator_${index}`} />
                    )}
                  </div>
                ))}
              </StoryListContainer>
            </SectionCard>
          </Section>
        </SectionContainer>
      </ScreenContainer>
      <EditEpisodeModal />
      <EditSeriesModal />
    </>
  );
};

const MobileSeriesHeaderImage = styled.img`
  display: none;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 5 / 6;

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const ImagePlaceholder = styled.div`
  display: none;
  width: 100%;
  height: 300px;
  background: ${({ theme }) => theme.colors.background.default};

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const StyledHeaderContainer = styled(HeaderContainer)`
  @media ${breakpoints.mobile} {
    padding: ${dimensions.size0};
  }
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  @media ${breakpoints.mobile} {
    position: absolute;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(33, 33, 44, 0.8) 0%,
      rgba(33, 33, 44, 0.8) 50%,
      rgba(33, 33, 44, 0.1) 100%
    );
    bottom: 0;
    padding: ${dimensions.size32} ${dimensions.size16} ${dimensions.size16};
  }
`;

const StyledHeaderTitleContainer = styled(HeaderTitleContainer)`
  max-width: 650px;
`;

const StoryHeaderImage = styled.div`
  min-width: 160px;

  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const StoryDescription = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;

const ImageHeaderContainer = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
`;

const StoryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.default};
`;

const Separator = styled.div`
  height: ${dimensions.size1};
  margin: ${dimensions.size24} ${dimensions.size0};
  background: ${({ theme }) => theme.colors.border.default[100]};
`;
