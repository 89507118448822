import { Badge, Button } from '@chakra-ui/react';
import {
  CmsCard,
  Icon,
  Link,
  ScreenContainer,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { env } from '@maestro/env';
import { useGetMyStoriesQuery } from '@maestro/graphql';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState';
import { TableLoader } from '../../../components/TableLoader';
import { CreateStoryDrawer } from './CreateStoryDrawer';

const imgUrl =
  'https://liwwunutcahympidrjyx.supabase.co/storage/v1/object/public/images/cms/home-cover.png';

export const MyStories = () => {
  const { data, isLoading } = useGetMyStoriesQuery();
  const list = data?.stories ?? [];
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ScreenContainer>
      <CreateStoryDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        branchId={env.VITE_CREATE_YOUR_OWN_STORY_BRANCH_ID}
      />
      <SectionContainer>
        <WorldCardCTA>
          <BackgroundContainer>
            <TitleContainer>
              <PageTitle>What will you create today?</PageTitle>
              <PageSubtitle>
                Create stories where your characters come to life through your
                choices and actions. Shape their journey, define their destiny
              </PageSubtitle>
              <PageCTA>
                I want to create a...
                <Button
                  variant="primary"
                  onClick={() => setIsOpen(true)}
                  leftIcon={<Icon name="sheet" size={rawDimensions.size16} />}
                >
                  New story
                </Button>
              </PageCTA>
            </TitleContainer>
          </BackgroundContainer>
        </WorldCardCTA>

        <SectionTitle>My stories</SectionTitle>
        {isLoading && list.length === 0 ? (
          <TableLoader />
        ) : list.length > 0 ? (
          <StoryFactoryBranchListContainer>
            {list.map((item) => (
              <Link to={`/story-details/${item.id}`} key={item.id}>
                <CmsCard
                  image={item.coverImage}
                  aspectRatio={1.5}
                  title={item.title}
                  description={item.title}
                >
                  {!!true && <Badge variant="statusActive">Published</Badge>}
                </CmsCard>
              </Link>
            ))}
          </StoryFactoryBranchListContainer>
        ) : (
          <EmptyState title="No story factory found." />
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

const StoryFactoryBranchListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${dimensions.size24};
  justify-content: start;
`;

const WorldCardCTA = styled.div`
  position: relative;
  min-height: 300px;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    min-height: 100px;
  }
`;

const TitleContainer = styled.div`
  width: 50%;

  @media ${breakpoints.tablet} {
    width: 100%;
  }
`;

const BackgroundContainer = styled.div`
  height: 100%;
  padding: ${dimensions.size48};
  background-image: url(${imgUrl});
  background-repeat: no-repeat;
  background-position: right;
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
  }
`;

const PageTitle = styled.div`
  ${textStyles.title.t32b}
  margin: ${dimensions.size24} 0;
  color: ${({ theme }) => theme.colors.text.header};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const PageSubtitle = styled.div`
  ${textStyles.body.b16m}
  margin: ${dimensions.size24} 0;
  color: ${({ theme }) => theme.colors.text.body};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const PageCTA = styled.div`
  ${textStyles.body.b16m}
  display: flex;
  gap: ${dimensions.size16};
  align-items: center;
  margin: ${dimensions.size24} 0;
  font-style: italic;
  color: ${({ theme }) => theme.colors.text.header};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;
