import { CloseButton } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { ImageFallback } from '@maestro/components/ImageFallback';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { Reorder, useDragControls } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { Story } from './sections.type';

type Props = {
  series: Story;
  onRemove: () => void;
};

export const DiscoverySeriesCard: React.FC<Props> = ({ series, onRemove }) => {
  const controls = useDragControls();

  return (
    <Reorder.Item value={series}>
      <SeriesCardContainer>
        <CloseContainer>
          <CloseButton onClick={onRemove} />
        </CloseContainer>
        {series.coverImage ? (
          <SeriesImageWrapper>
            <SeriesImage
              className="reorder-handle"
              onPointerDown={(e) => {
                e.preventDefault();
                controls.start(e);
              }}
              src={toOptimizedImageUri(series.coverImage, {
                width: 200,
                height: 298,
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
            />
          </SeriesImageWrapper>
        ) : (
          <StyledImageFallback
            width={200}
            height={300}
            iconSize={rawDimensions.size32}
          />
        )}
        <DetailsContainer>
          <Title>{series.name}</Title>
        </DetailsContainer>
      </SeriesCardContainer>
    </Reorder.Item>
  );
};

const SeriesCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${dimensions.size200};
  min-width: ${dimensions.size200};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: ${({ theme }) => theme.colors.base.light[900]};
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;

const SeriesImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.base.light[100]};
  border-radius: ${dimensions.size8} ${dimensions.size8} ${dimensions.size0}
    ${dimensions.size0};
`;

const SeriesImage = styled.img`
  width: 200px;
  min-width: 200px;
  height: 298px;
  min-height: 298px;
  cursor: grab;
  object-fit: cover;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledImageFallback = styled(ImageFallback)`
  border: ${dimensions.size0};
  border-radius: ${dimensions.size8} ${dimensions.size8} ${dimensions.size0}
    ${dimensions.size0};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  padding: ${dimensions.size12};
  cursor: grab;
`;

const Title = styled.div`
  ${textStyles.label.lb14sb}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.header};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
