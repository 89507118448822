import { FormControl } from '@maestro/components';
import { EpisodeTemplateFormFieldFragment } from '@maestro/graphql';
import React from 'react';
import styled from 'styled-components';
import { Hint } from '../../../components/Hint';
import { StoryFieldInput } from './StoryFieldInput';

type Props = {
  allowEmpty: boolean;
  templateFields: EpisodeTemplateFormFieldFragment[];
  values: Record<string, string | number>;
  onChange: (field: string, value: string | number) => void;
};

export const StoryCreationForm: React.FC<Props> = (props) => {
  const { onChange, values, templateFields, allowEmpty } = props;

  return (
    <Container>
      {templateFields.map((field) => (
        <FormControl key={field.id} label={field.label}>
          {field.description && <Hint>{field.description}</Hint>}
          <StoryFieldInput
            allowEmpty={allowEmpty}
            field={field}
            value={values[field.id]}
            onChange={(value) => onChange(field.id, value)}
          />
        </FormControl>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: -16px;
`;
