import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import { EpisodeTemplateFormFieldFragment } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ImageDropdown } from './ImageDropdown';

type Props = {
  allowEmpty: boolean;
  value: string;
  onChange: (value: string) => void;
  options: EpisodeTemplateFormFieldFragment['options'];
};

export const EnumDropdown: React.FC<Props> = ({
  value,
  onChange,
  options,
  allowEmpty,
}) => {
  if (
    options &&
    options.length > 0 &&
    options[0].component === 'optionWithImage'
  ) {
    const imageOptions = options.map((option) => ({
      imageUrl: option.componentProps!.imageUrl,
      label: option.label,
      value: option.value,
    }));

    return (
      <ImageDropdown
        options={imageOptions}
        selectedOption={value}
        onSelectOption={onChange}
      />
    );
  }

  return (
    <Menu>
      <MenuDropdownButton>
        {value ?? <Placeholder>Select value</Placeholder>}
      </MenuDropdownButton>
      <MenuList>
        {allowEmpty && (
          <MenuItem onClick={() => onChange(undefined as never)}>
            <ValueContainer>
              <Empty>(Empty)</Empty>
            </ValueContainer>
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} onClick={() => onChange(option.value)}>
            <ValueContainer>
              <Name>{option.label}</Name>
            </ValueContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Empty = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
