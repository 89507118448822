import { Button, Tooltip } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { useDrawerStore } from '@maestro/studio/hooks/useDrawerStore';
import { rawDimensions } from '@maestro/styles';

export const PlayChapterButton = () => {
  const { openDrawer } = useDrawerStore();

  const playOnBrowser = () => openDrawer('play-episode');

  return (
    <Tooltip label="Play chapter" placement="bottom-end">
      <Button
        variant="primary"
        borderRadius={rawDimensions.size8}
        onClick={playOnBrowser}
        leftIcon={<Icon name="play" size={rawDimensions.size16} />}
      >
        Play chapter
      </Button>
    </Tooltip>
  );
};
