import { useMaestroToast } from '@maestro/components';
import {
  ChapterFragment,
  ChapterStatus,
  ListChaptersQuery,
} from '@maestro/graphql';
import { useRealtimeClient } from '@maestro/studio/realtime/useRealtimeClient';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

export const useWatchStoryEvents = (storyId: string) => {
  const { realtimeClient } = useRealtimeClient();
  const queryClient = useQueryClient();
  const toast = useMaestroToast();

  const channel = useMemo(() => {
    if (!realtimeClient) return;

    return realtimeClient.channels.get(`story:${storyId}`);
  }, [realtimeClient, storyId]);

  useEffect(() => {
    if (!channel) return;

    channel
      .subscribe('ChapterStatus', (message) => {
        const statusMap: Record<string, ChapterStatus> = {
          pending: ChapterStatus.Creating,
          succeeded: ChapterStatus.Ready,
          failed: ChapterStatus.ReadyToCreate,
        };
        const status = statusMap[message.data.status];

        if (message.data.status === 'failed') {
          toast({
            title: 'Chapter creation failed',
            description: 'Please try again',
            status: 'warning',
          });
        } else if (message.data.status === 'succeeded') {
          toast({
            title: 'Chapter created',
            description: 'The chapter is now ready to play',
            status: 'success',
          });
        } else if (message.data.status === 'pending') {
          toast({
            title: 'Chapter is being created',
            description: 'Please wait while the chapter is being created',
            status: 'success',
          });
        }

        if (!status) return;

        const chapterId = message.data.chapterId;

        queryClient.setQueryData<ListChaptersQuery>(
          ['listChapters', { storyId }],
          (old) => {
            if (!old) return old;

            const chapters: ChapterFragment[] = [];

            old.chapters.forEach((chapter, index) => {
              if (chapter.id === chapterId) {
                chapters.push({ ...chapter, status });
              } else if (chapter.status === ChapterStatus.Locked) {
                const previousChapter = chapters[index - 1];

                if (previousChapter?.status === ChapterStatus.Ready) {
                  chapters.push({
                    ...chapter,
                    status: ChapterStatus.ReadyToCreate,
                  });
                } else {
                  chapters.push(chapter);
                }
              } else {
                chapters.push(chapter);
              }
            });

            return { ...old, chapters };
          },
        );
      })
      .catch(() => void 0);

    return () => channel.unsubscribe('ChapterStatus');
  }, [channel]);

  return {
    isLoading: !channel,
  };
};
