import { StudioFlowState, StudioNodeData } from '@common/studio-types';
import { MaestroGameMessage } from '@maestro/studio/components/simulator/gameMessages/MaestroGameMessage';
import { useGameSimulator } from '@maestro/studio/components/simulator/hooks/useGameSimulator';
import { CollapsedPlayerMenu } from '@maestro/studio/components/simulator/playerMenu/CollapsedPlayerMenu';
import { dimensions } from '@maestro/styles';
import { useEffect, useRef } from 'react';
import { Edge, Node } from 'reactflow';
import styled from 'styled-components';

type Props = {
  flowState: StudioFlowState<Node<StudioNodeData>, Edge>;
};

export const PlayChapterSimulator: React.FC<Props> = ({ flowState }) => {
  const game = useGameSimulator({ flowState });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.parentElement) {
      const element = ref.current.lastElementChild as HTMLDivElement | null;

      if (element) {
        const drawerHeight = ref.current.parentElement.offsetHeight;
        const addedElementHeight = element.offsetHeight;
        const minimumScroll = 88;

        ref.current.parentElement.scrollTo({
          behavior: 'smooth',
          top:
            element.offsetTop -
            (addedElementHeight > drawerHeight
              ? minimumScroll
              : Math.min(300, drawerHeight - addedElementHeight)),
        });
      }
    }
  }, [game.messages.length]);

  return (
    <Container>
      <ScrollContainer>
        <GameFeed ref={ref}>
          {game.messages.map((message, index) => (
            <div key={index}>
              <MaestroGameMessage
                message={message}
                game={game}
                isLastMessage={index + 1 === game.messages.length}
              />
            </div>
          ))}
        </GameFeed>
      </ScrollContainer>
      <HudContainer>
        <CollapsedPlayerMenu game={game} />
      </HudContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const HudContainer = styled.div`
  width: 100%;
`;

const GameFeed = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
  padding: ${dimensions.size24};
  margin-bottom: calc(100dvh - 300px);
`;
