import { NumericInput, Textarea } from '@maestro/components';
import {
  EpisodeTemplateFormFieldFragment,
  EpisodeTemplateFormFieldType,
} from '@maestro/graphql';
import React from 'react';
import { EnumDropdown } from './EnumDropdown';

type Props = {
  allowEmpty: boolean;
  field: EpisodeTemplateFormFieldFragment;
  value: string | number;
  onChange: (value: string | number) => void;
};

export const StoryFieldInput: React.FC<Props> = (props) => {
  const { field, value, onChange } = props;

  if (field.type === EpisodeTemplateFormFieldType.Number) {
    return (
      <NumericInput
        flex={1}
        placeholder="Value"
        value={value}
        min={-1000}
        onChange={(valueAsNumber) => onChange(valueAsNumber)}
      />
    );
  }

  if (field.type === EpisodeTemplateFormFieldType.String) {
    return (
      <Textarea
        placeholder="Type value"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }

  if (field.type === EpisodeTemplateFormFieldType.Select) {
    return (
      <EnumDropdown
        allowEmpty={props.allowEmpty}
        value={value as string}
        options={field.options ?? []}
        onChange={(selectedOption: string) => onChange(selectedOption)}
      />
    );
  }
};
