import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  options: Array<{ imageUrl: string; label: string; value: string }>;
  selectedOption: string;
  onSelectOption: (option: string) => void;
};

export const ImageDropdown: React.FC<Props> = ({
  options,
  selectedOption,
  onSelectOption,
}) => {
  return (
    <Container>
      {options.map((option) => (
        <ImageContainer
          key={option.imageUrl}
          $isSelected={selectedOption === option.value}
          onClick={() => onSelectOption(option.value)}
        >
          <ImageImage src={option.imageUrl} />
          <ImageLabel>{option.label}</ImageLabel>
        </ImageContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${dimensions.size12};
  overflow-y: auto;
  margin-bottom: ${dimensions.size12};
`;

const ImageImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: ${dimensions.size8};
  user-drag: none;
  -webkit-user-drag: none;
`;

const ImageContainer = styled.div<{ $isSelected: boolean }>`
  width: calc(50% - ${dimensions.size12});
  border-radius: ${dimensions.size12};
  border: 3px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.background.accent : 'transparent'};
  position: relative;
`;

const ImageLabel = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.header};
  position: absolute;
  bottom: ${dimensions.size8};
  left: ${dimensions.size8};
  background-color: ${({ theme }) => theme.colors.base.dark[800]};
  border-radius: ${dimensions.size8};
  padding: ${dimensions.size4} ${dimensions.size12};
`;
